import React from "react"
import { get } from "lodash"
import { Link, withPrefix } from "../../utils"

export default function ActionLink(props) {
  let action = get(props, "action", null)
  let class_names = get(props, "class_names", null)
  let page = action.url.replace("/", "")

  return (
    <Link
      to={withPrefix(get(action, "url", null))}
      {...(get(action, "newWindow", null)
        ? { target: "_blank", rel: "noopener" }
        : null)}
      className={class_names}
    >
      {get(action, "label", null)}
    </Link>
  )
}
